import React from 'react';

import { ArrowIcon } from './styles';

import { Position, ArrowColor } from '../../types';

export type ArrowType = {
  position: Position;
  color: ArrowColor;
};

const Arrow = ({ position, color }: ArrowType): JSX.Element => (
  <ArrowIcon
    color={color}
    position={position}
  />
);

export default Arrow;
