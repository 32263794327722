import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { Container, Content, ImageContainer, StyledLogo } from './styles';

import Paragraph from '../../components/Paragraph';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <ImageContainer>
          <StyledLogo />
        </ImageContainer>
        <Paragraph color="darkBlue">
          { t('footerDescription') }
        </Paragraph>
        <Paragraph color="darkBlue">
          { t('footerAddress') }
        </Paragraph>
      </Content>
    </Container>
  );
};

export default Footer;
