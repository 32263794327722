import styled from 'styled-components';

import { TextColor } from '../../types';

type Props = {
  color: TextColor;
};

export const StyledParagraph = styled.div<Props>`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 56px;
  white-space: pre-line;
  color: ${({ color }: Props): string => (color === 'darkBlue' && '#0E1C30')
    || (color === 'blue' && '#2FA7D9')
    || '#FFFFFF'
};

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 60px;
  }
`;
