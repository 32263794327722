import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Barlow";
  }

  h1, h2, h3 {
    color: #222D52;
  }

  p {
    color: #111113;
  }

  .swiper {
    background-color: #FAFAFA;
  }

  .swiper-button-prev::after, .swiper-button-next::after {
    transform: scale(0.5);
  }

  .swiper-button-prev:hover, .swiper-button-next:hover {
    opacity: 0.9;
  }

  .swiper-button-prev {
    background-color: #56A5D4;
    margin-left: -10px;
    color: #FFFFFF;
    padding: 40px 22px 40px 18px;
    border-radius: 0px 4px 4px 0px;
  }

  .swiper-button-next {
    background-color: #FFFFFF;
    margin-right: -10px;
    color: #353535;
    padding: 40px 18px 40px 22px;
    border-radius: 4px 0px 0px 4px;
  }

  .swiper-pagination {
    left: 25% !important;
    bottom: 60px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #56A5D4 !important;
  }

  @media only screen and (max-width: 992px) {
    .swiper-button-prev, .swiper-button-next {
      top: 50vh;
      color: #FFFFFF;
      background-color: #56A5D4;
    }

    .swiper-pagination {
      display: none;
    }
  }
`;
