import styled from 'styled-components';

import { TextColor } from '../../types';

type TitleProps = {
  color: TextColor;
};

export const StyledTitle = styled.h2<TitleProps>`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 100px;
  line-height: 116px;
  margin-bottom: 60px;
  color: ${({ color }: TitleProps): string => (color === 'darkBlue' && '#0E1C30')
    || (color === 'blue' && '#2FA7D9')
    || '#FFFFFF'
};

  @media only screen and (max-width: 1400px) {
    font-size: 80px;
    line-height: 90px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px;
  }
`;
