import React from 'react';

import { Container, ImageContainer, Header } from './styles';

import Paragraph from '../Paragraph';

type Props = {
  image: JSX.Element;
  title: string;
  description: string;
};

const IconCard = ({ image, title, description }: Props): JSX.Element => (
  <Container>
    <ImageContainer>
      { image }
    </ImageContainer>
    <Header>
      { title }
    </Header>
    <Paragraph color="white">
      { description }
    </Paragraph>
  </Container>
);

export default IconCard;
