import styled from 'styled-components';

export const ImageContainer = styled.div`
  margin-bottom: 56px;
`;

export const Header = styled.h3`
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 30px;
  color: #FFFFFF;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 1200px) {
    ${Header} {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
`;
