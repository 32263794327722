import React from 'react';

import { Container, ImageContainer, Content, Wrapper, PreHeader, TitleHeader, Highlight } from './styles';

import Arrow, { ArrowType } from '../../components/Arrow';
import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import { Direction, CardColor, TextColor } from '../../types';

export { Highlight as MediumHighlight };

type Props = {
  image: JSX.Element;
  preTitle?: string;
  title?: string;
  titleColor?: TextColor;
  titleImage?: JSX.Element;
  description: string | JSX.Element;
  descriptionColor?: TextColor;
  cardColor?: CardColor;
  direction?: Direction;
  arrow?: ArrowType;
  children?: JSX.Element;
};

const HalfTile = ({
  image,
  preTitle,
  title,
  titleColor = 'blue',
  titleImage,
  description,
  descriptionColor = 'darkBlue',
  direction = 'row',
  arrow = { position: 'top-left', color: 'grey' },
  cardColor = 'grey',
  children
}: Props): JSX.Element => (
  <Container
    color={cardColor}
    direction={direction}
  >
    <ImageContainer>
      { image }
    </ImageContainer>
    <Content>
      <Arrow
        color={arrow.color}
        position={arrow.position}
      />
      <Wrapper>
        { preTitle
          && (
            <PreHeader>
              { preTitle }
            </PreHeader>
          ) }
        { title
          && (
            <Title color={titleColor}>
              { title }
            </Title>
          ) }
        { titleImage
          && (
            <TitleHeader>
              { titleImage }
            </TitleHeader>
          ) }
        <Paragraph color={descriptionColor}>
          { description }
        </Paragraph>
        { children }
      </Wrapper>
    </Content>
  </Container>
);

export default HalfTile;
