import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { Container } from './styles';

import Brain from '../../assets/svg/icon-brain.svg';
import Chip from '../../assets/svg/icon-chip.svg';
import Eye from '../../assets/svg/icon-eye.svg';
import IconCard from '../../components/IconCard';

const IconTile = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <IconCard
        description={t('iconOneDescription')}
        image={<Chip style={{ height: '100px', width: '100px' }} />}
        title={t('iconOneTitle')}
      />
      <IconCard
        description={t('iconTwoDescription')}
        image={<Eye style={{ height: '100px', width: '100px' }} />}
        title={t('iconTwoTitle')}
      />
      <IconCard
        description={t('iconThreeDescription')}
        image={<Brain style={{ height: '100px', width: '100px' }} />}
        title={t('iconThreeTitle')}
      />
    </Container>
  );
};

export default IconTile;
