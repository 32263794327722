import styled from 'styled-components';

export const Column = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px;
  position: relative;
`;

export const Content = styled.div`
  max-width: 680px;
`;

export const ImageContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 160px);
  left: 80px;
  top: 60px;
  gap: 48px;
`;

export const Header = styled.h1`
  color: #0E1C30;
  font-weight: 900;
  font-size: 100px;
  line-height: 116px;
  margin-bottom: 60px;
  white-space: pre-line;
`;

export const HeaderHighlight = styled.span`
  color: #56A5D4;
`;

export const Paragraph = styled.p`
  color: #111113;
  font-size: 24px;
  line-height: 34px;
  white-space: pre-line;
`;

export const ImageColumn = styled.div`
  width: 50%;
`;

export const LanguageList = styled.ul`
  display: flex;
  gap: 10px;
`;

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  min-height: 840px;
  display: flex;
  background-color: #FFFFFF;

  @media only screen and (max-width: 1400px) {
    ${Header} {
      font-size: 80px;
      line-height: 90px;
      margin-bottom: 40px;
    }
  }

  @media only screen and (max-width: 1200px) {
    ${Header} {
      font-size: 60px;
      line-height: 70px;
      margin-bottom: 40px;
    }

    ${Paragraph} {
      font-size: 20px;
      line-height: 32px;
    }
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    height: auto;

    ${Column} {
      width: 100%;
      padding-top: 180px;
    }

    ${ImageColumn} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 576px) {
    ${Column} {
      width: 100%;
      padding: 160px 60px 80px 60px;
    }

    ${ImageContainer} {
      width: calc(100% - 120px);
      left: 60px;
    }

    ${Header} {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 24px;
    }

    ${LanguageList} {
      margin-top: 6px;
    }
  }
`;
