import React from 'react';

import { StyledTitle } from './styles';

import { TextColor } from '../../types';

type Props = {
  children: string;
  color: TextColor;
};

const Title = ({ children, color }: Props): JSX.Element => (
  <StyledTitle color={color}>
    { children }
  </StyledTitle>
);

export default Title;
