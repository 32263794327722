import React from 'react';

import { GlobalStyle } from './styles';

type Props = {
  children: JSX.Element;
};

const Layout = ({ children }: Props): JSX.Element => (
  <>
    <GlobalStyle theme="purple" />
    { children }
  </>
);

export default Layout;
