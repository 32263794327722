import React from 'react';

import { Container, Content, ImageContainer } from './styles';

import Arrow, { ArrowType } from '../../components/Arrow';
import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import { CardColor, TextColor } from '../../types';

type Props = {
  title: string;
  titleColor?: TextColor;
  description: string;
  descriptionColor?: TextColor;
  cardColor?: CardColor;
  arrow?: ArrowType;
  children?: JSX.Element;
};

const CenterTile = ({
  title,
  titleColor = 'blue',
  description,
  descriptionColor = 'darkBlue',
  arrow = { position: 'top-left', color: 'grey' },
  cardColor = 'grey',
  children
}: Props): JSX.Element => (
  <Container color={cardColor}>
    <Arrow
      color={arrow.color}
      position={arrow.position}
    />
    <Content>
      <Title color={titleColor}>
        { title }
      </Title>
      <Paragraph color={descriptionColor}>
        { description }
      </Paragraph>
      { children
        && (
          <ImageContainer>
            { children }
          </ImageContainer>
        ) }
    </Content>
  </Container>
);

export default CenterTile;
