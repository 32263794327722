import React from 'react';

import { Container, Column, Content, ImageContainer, Header, HeaderHighlight, Paragraph, ImageColumn, LanguageList } from './styles';

import PanoptesLogo from '../../assets/svg/panoptes.svg';

export { HeaderHighlight as Highlight };

type Props = {
  title: string | JSX.Element;
  description: string;
  image: JSX.Element;
  languageList: JSX.Element[];
};

const Hero = ({ title, description, image, languageList }: Props): JSX.Element => (
  <Container>
    <Column>
      <ImageContainer>
        <PanoptesLogo />
        <LanguageList>
          { languageList }
        </LanguageList>
      </ImageContainer>
      <Content>
        <Header>
          { title }
        </Header>
        <Paragraph>
          { description }
        </Paragraph>
      </Content>
    </Column>
    <ImageColumn>
      { image }
    </ImageColumn>
  </Container>
);

export default Hero;
