import styled from 'styled-components';

import Icon from '../../assets/svg/arrow.svg';
import { Position, ArrowColor } from '../../types';

type Props = {
  position: Position;
  color: ArrowColor;
};

export const ArrowIcon = styled(Icon)<Props>`
  position: absolute;
  top: ${({ position }: Props): string => ((position === 'top-left' || position === 'top-right') && '60px')
    || 'unset'
};
  left: ${({ position }: Props): string => ((position === 'top-left' || position === 'bottom-left') && '60px')
    || 'unset'
};
  right: ${({ position }: Props): string => ((position === 'top-right' || position === 'bottom-right') && '60px')
    || 'unset'
};
  bottom: ${({ position }: Props): string => ((position === 'bottom-left' || position === 'bottom-right') && '60px')
    || 'unset'
};
  transform: scale(0.8) ${({ position }: Props): string => (position === 'top-left' && 'rotate(0)')
    || (position === 'top-right' && 'rotate(90deg)')
    || (position === 'bottom-right' && 'rotate(180deg)')
    || 'rotate(270deg)'
};

  path {
    fill: ${({ color }: Props): string => (color === 'grey' && '#C7C9CB')
      || (color === 'blue' && '#2FA7D9')
      || (color === 'orange' && '#E85312')
      || '#59A21F'
};
  }

  @media only screen and (max-width: 1400px) {
    top: ${({ position }: Props): string => ((position === 'top-left' || position === 'top-right') && '32px')
    || 'unset'
};
    left: ${({ position }: Props): string => ((position === 'top-left' || position === 'bottom-left') && '32px')
    || 'unset'
};
right: ${({ position }: Props): string => ((position === 'top-right' || position === 'bottom-right') && '32px')
    || 'unset'
};
bottom: ${({ position }: Props): string => ((position === 'bottom-left' || position === 'bottom-right') && '32px')
    || 'unset'
};
  }

  @media only screen and (max-width: 992px) {
    top: ${({ position }: Props): string => ((position === 'top-left' || position === 'top-right') && '16px')
    || 'unset'
};
    left: ${({ position }: Props): string => ((position === 'top-left' || position === 'bottom-left') && '16px')
    || 'unset'
};
right: ${({ position }: Props): string => ((position === 'top-right' || position === 'bottom-right') && '16px')
    || 'unset'
};
bottom: ${({ position }: Props): string => ((position === 'bottom-left' || position === 'bottom-right') && '16px')
    || 'unset'
};
  }

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;
