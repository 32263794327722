import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 160px 200px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;
  background-color: #0E1C30;

  @media only screen and (max-width: 1600px) {
    padding: 160px;
  }

  @media only screen and (max-width: 1400px) {
    padding: 160px 80px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 120px 60px;
  }
  
  @media only screen and (max-width: 992px) {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    grid-row-gap: 60px;
  }

  @media only screen and (max-width: 576px) {
    padding: 60px 40px;
  }
`;
