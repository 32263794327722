import styled from 'styled-components';

import { CardColor } from '../../types';

type Props = {
  color: CardColor;
};

export const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

export const ImageContainer = styled.div`
  margin-top: 100px;
`;

export const Container = styled.div<Props>`
  width: 100%;
  position: relative;
  padding: 120px;
  background-color: ${({ color }: Props): string => (color === 'white' && '#FFFFFF')
    || '#FAFAFA'
};

  @media only screen and (max-width: 992px) {
    padding: 80px;
    padding-bottom: 120px;
  }

  @media only screen and (max-width: 576px) {
    padding: 60px 40px;
    padding-bottom: 80px;

    ${ImageContainer} {
      margin-top: 40px;
    }
  }
`;
