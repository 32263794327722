import React from 'react';

import { StyledParagraph } from './styles';

import { TextColor } from '../../types';

type Props = {
  children: string | JSX.Element;
  color: TextColor;
};

const Paragraph = ({ children, color }: Props): JSX.Element => (
  <StyledParagraph color={color}>
    { children }
  </StyledParagraph>
);

export default Paragraph;
