import styled from 'styled-components';

import { Direction, CardColor } from '../../types';

type Props = {
  color: CardColor;
  direction?: Direction;
};

export const ImageContainer = styled.div`
  width: 50%;
`;

export const Content = styled.div`
  position: relative;
  width: 50%;
  margin: 0 auto;
  padding: 120px 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  max-width: 680px;
`;

export const PreHeader = styled.div`
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 12px;
  color: #0E1C30;
`;

export const TitleHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;

export const Highlight = styled.span`
  font-weight: 500;
`;

export const Container = styled.div<Props>`
  width: 100%;
  display: flex;
  background-color: ${({ color }: Props): string => (color === 'white' && '#FFFFFF')
    || (color === 'grey' && '#FAFAFA')
    || '#0E1C30'
};
  flex-direction: ${({ direction }: Props): string => (direction === 'row' && 'row')
    || 'row-reverse'
};

  @media only screen and (max-width: 1600px) {
    ${Content} {
      padding: 120px 160px;
    }
  }

  @media only screen and (max-width: 1400px) {
    ${ImageContainer} {
      width: 40%;
    }

    ${Content} {
      width: 60%;
      padding: 120px;
    }
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;

    ${ImageContainer} {
      width: 100%;
    }

    ${Content} {
      width: 100%;
      padding: 80px;
    }

    ${TitleHeader} {
      margin-bottom: 40px;
    }
  }

  @media only screen and (max-width: 576px) {
    ${Content} {
      padding: 60px 40px;
    }
  }
`;
