import styled from 'styled-components';

import Logo from '../../assets/svg/panoptes.svg';

export const Container = styled.div`
  width: 100%;
  padding: 120px;
  text-align: center;
  background-color: #FFFFFF;

  @media only screen and (max-width: 576px) {
    padding: 40px;
  }
`;

export const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const ImageContainer = styled.div`
  margin-bottom: 56px;
`;

export const StyledLogo = styled(Logo)`
  max-width: 240px;
  width: 100%;
  height: auto;
`;
